import React, { useEffect, useState } from "react";
import AddMenuItemForm from "./inc/AddMenuItemForm";
import MenuItemsTable from "./inc/MenuItemsTable";
import serverConfiguration from "../app.config.json";
import axios from "axios";
import { useAuth } from "../context";
const SERVER_URL =
  serverConfiguration.env === "dev"
    ? serverConfiguration.backend.dev.SERVER
    : serverConfiguration.backend.production.SERVER;


export default function MenuItem() {
  const {currentUser} = useAuth();
  const [parentOptions, setParentOptions] = useState([]);

  useEffect(() => {
    // Récupérer les options de menus parent
    const fetchParentOptions = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/menu-items`,
          {
            headers: { Authorization: `Bearer ${currentUser}` }, // Headers should be the third parameter
          }
        ); // Remplacez par votre endpoint
        // setParentOptions(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching parent options:", error);
      }
    };

    fetchParentOptions();
  }, []);

  return (
    <>
      <div className="app-content-area">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              {/* Page header */}
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h3 className="mb-0">
                  {" "}
                  <i className="bi bi-layout-sidebar-inset"></i> Menu
                  d'administration
                </h3>
              </div>
              <div className="row">
                <div className="col-xl-9 col-12 animate-right">
                  <div className="card">
                    <div className="card-header d-md-flex">
                      <div className="col">
                        <h5>Menu</h5>
                      </div>
                    </div>
                    <div className="card-body">
                      <MenuItemsTable menuData={[]} />
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-12">
                  <AddMenuItemForm parentOptions={parentOptions} />
                </div>
              </div>
              {/* Add menu item form */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
