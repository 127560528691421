import React, { useEffect, useState } from "react";
import Medias from "../global/Medias";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useAuth } from "../context";
import Loader from "../layouts/Loader";
import CompoLoader from "../layouts/CompoLoader";
import ThemeSwitcher from "../global/ThemeSwitcher";
import config from "../app.config.json";

export default function Login() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { validateUsername, validatePassword } = useAuth();
  const [values, setValues] = useState({
    username: "",
    password: "",
    rememberMe: false,
  });
  const [userCredentials, setUserCredentials] = useState({
    password: "",
    user_id: "",
  });
  const [isFirstStep, setIsFirstStep] = useState(true);
  const [isLoadingForm, setIsLoadingForm] = useState(false);

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleValidateUsername = async (e) => {
    e.preventDefault();
    if (!values.username.trim()) {
      return toast.error("Please provide your username!", {
        position: "top-right",
        duration: 6000,
      });
    }

    setIsLoadingForm(true);
    try {
      const validateUsernameReq = await validateUsername(values);
      if (!validateUsernameReq.user_id) {
        throw new Error("Invalid response from server");
      }
      setUserCredentials({ user_id: validateUsernameReq.user_id });
      setIsFirstStep(false);
    } catch (error) {
      const errorMessage =
        error.code === "ERR_NETWORK"
          ? "Network error, please try later!"
          : error.response?.data?.message || "An error occurred!";
      toast.error(errorMessage, { position: "top-right", duration: 9000 });
    } finally {
      setIsLoadingForm(false);
    }
  };

  const handleValidatePassword = async (e) => {
    e.preventDefault();
    if (!values.password.trim()) {
      return toast.error("Please provide your password!", {
        position: "top-right",
        duration: 6000,
      });
    }

    setIsLoadingForm(true);
    try {
      const response = await validatePassword({
        password: values.password,
        user_id: userCredentials.user_id,
      });
      toast.success("Login successful!", { position: "top-right" });
      navigate("/", { replace: true });
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Connection error, please try again!";
      toast.error(errorMessage, { position: "top-right" });
    } finally {
      setIsLoadingForm(false);
    }
  };

  useEffect(() => {
    const currentUser = JSON.parse(sessionStorage.getItem("user"));
    document.title = `BUS VANILLA | Authentification`;

    if (!currentUser) {
      setLoading(false); // Arrêter le chargement si aucun utilisateur n'est connecté
    } else {
      navigate("/", { replace: true }); // Rediriger l'utilisateur connecté
    }
  }, []); // Retirez `navigate` des dépendances

  if (loading) return <Loader />;

  return (
    <div style={{ position: "relative", overflow: "hidden" }}>
      {/* Arrière-plan flouté */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: `url(${Medias.patern_background})`,
          backgroundSize: "cover",
          filter: "blur(8px)", // Floutage de l'arrière-plan
          zIndex: 1,
        }}
      />

      <main
        className="container-fluid d-flex flex-column"
        style={{ position: "relative", zIndex: 2 }}
      >
        <div className="container">
          <div style={{ position: "absolute", bottom: "1rem", right: "2rem" }}>
            <ThemeSwitcher />
            <small className="text-secondary">
              {config.app_name} V{config.version}, C{config.client}
            </small>
          </div>
          <div className="row align-items-center justify-content-center g-0 min-vh-100">
            <div className="col-12 col-md-8 col-lg-5 col-xxl-4 py-8 py-xl-0">
              <div className="card smooth-shadow-sm">
                {isLoadingForm && <CompoLoader />}
                <div className="card-body p-6">
                  <div className="mb-4">
                    <a href="/">
                      <img
                        style={{ height: "28px" }}
                        src={Medias.logo_sid_bar}
                        className="mb-2 text-inverse"
                        alt="Logo"
                      />
                    </a>
                    <p className="mb-6">
                      Renseignez votre identifiant pour vous connecter
                    </p>
                  </div>
                  <form
                    onSubmit={
                      isFirstStep
                        ? handleValidateUsername
                        : handleValidatePassword
                    }
                  >
                    {isFirstStep ? (
                      <div className="mb-5">
                        <label htmlFor="username" className="form-label">
                          Votre identifiant
                        </label>
                        <input
                          type="text"
                          id="username"
                          className="form-control"
                          name="username"
                          placeholder="Nom d'utilisateur"
                          value={values.username}
                          onChange={handleInputChange}
                          required
                          autoComplete="off"
                          autoFocus
                        />
                      </div>
                    ) : (
                      <div className="animate-left">
                        <div className="mb-3">
                          <label htmlFor="password" className="form-label">
                            Mot de passe
                          </label>
                          <input
                            type="password"
                            id="password"
                            className="form-control"
                            name="password"
                            placeholder="******"
                            value={values.password}
                            onChange={handleInputChange}
                            required
                            autoComplete="off"
                            autoFocus
                          />
                        </div>
                        <div className="d-lg-flex justify-content-between align-items-center mb-4">
                          <div className="form-check custom-checkbox">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="rememberMe"
                              name="rememberMe"
                              checked={values.rememberMe}
                              onChange={handleInputChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="rememberMe"
                            >
                              Se souvenir de moi
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                    <div>
                      <div className="d-grid col-4">
                        <button
                          type="submit"
                          className="btn btn-primary btn-sm"
                          disabled={isLoadingForm}
                        >
                          {isFirstStep ? "Suivant" : "Se connecter"}
                        </button>
                      </div>
                      <div className="d-md-flex justify-content-end mt-4">
                        <Link to="#" className="text-muted fs-5">
                          J'ai oublié mon mot de passe?
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-12 text-center mt-5">
                <div>
                  &copy;{" "}
                  <Link
                    to="https://www.linkedin.com/in/ndfranz"
                    target="_blank"
                    className="text-muted"
                  >
                    NDfranz
                  </Link>{" "}
                  |
                  <Link to="#" target="_blank" className="text-muted">
                    Condition d'utilisation
                  </Link>{" "}
                  |
                  <Link to="!#" target="_blank" className="text-muted">
                    Termes & conditions
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Toaster />
    </div>
  );
}
