import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import Medias from "../global/Medias";
import { useAuth } from "../context";

export default function SidBar() {
  const location = useLocation();
  const { menuData } = useAuth();
  const [menuDataToUse, setMenuDataToUse] = useState([]);

  const menuDataFetch = async () => {
    try {
      const data = await menuData();
      setMenuDataToUse(data);
      console.log(data);
    } catch (error) {
      setMenuDataToUse([]);
      console.error("API Request Error (/menu-items):", error);
    }
  };

  useEffect(() => {
    menuDataFetch();
  }, []);

  const isActive = (path) => location.pathname === path;

  const groupedMenu = menuDataToUse.reduce((acc, item) => {
    const category = item.category || "Main";
    if (!acc[category]) acc[category] = [];
    acc[category].push(item);
    return acc;
  }, {});

  const routeTitles = menuDataToUse.reduce((acc, item) => {
    if (item.path) acc[item.path] = item.label;
    if (item.submenus) {
      item.submenus.forEach((submenu) => {
        if (submenu.path) acc[submenu.path] = submenu.label;
      });
    }
    return acc;
  }, {});

  useEffect(() => {
    const activeTitle = routeTitles[location.pathname] || "Dashboard";
    document.title = `BUS VANILLA | ${activeTitle}`;
  }, [location.pathname]);

  return (
    <div className="app-menu">
      <div className="navbar-vertical navbar nav-dashboard">
        <div className="h-100" data-simplebar="" style={{ overflowY: "auto" }}>
          <PerfectScrollbar>
            <Link className="navbar-brand" to="/">
              <img src={Medias.logo_sid_bar} alt="bus-logo" />
            </Link>

            <ul className="navbar-nav flex-column" id="sidebarMenu">
              {Object.keys(groupedMenu).map((category, index) => (
                <React.Fragment key={index}>
                  {category !== "Main" && (
                    <li className="nav-item">
                      <div className="navbar-heading">{category}</div>
                    </li>
                  )}
                  {groupedMenu[category].map((item, idx) => (
                    <li
                      key={idx}
                      className={`nav-item ${isActive(item.path) ? "active-current-text" : ""}`}
                    >
                      {item.submenus && item.submenus.length > 0 ? (
                        <>
                          <Link
                            className="nav-link has-arrow collapsed"
                            to="#"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse-${index}-${idx}`}
                            aria-expanded="false"
                            aria-controls={`collapse-${index}-${idx}`}
                          >
                            <i className={item.icon}></i>&nbsp; {item.label}
                          </Link>
                          <div id={`collapse-${index}-${idx}`} className="collapse">
                            <ul className="nav flex-column">
                              {item.submenus.map((submenu, subIdx) => (
                                <li
                                  key={subIdx}
                                  className={`nav-item ${isActive(submenu.path) ? "active-current-text" : ""}`}
                                >
                                  <Link className="nav-link" to={submenu.path}>
                                    <i className={submenu.icon}></i>&nbsp; {submenu.label}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </>
                      ) : (
                        <Link className="nav-link" to={item.path}>
                          <i className={item.icon}></i>&nbsp; {item.label}
                        </Link>
                      )}
                    </li>
                  ))}
                </React.Fragment>
              ))}
            </ul>

            <div className="card bg-light shadow-none text-center mx-4 my-8">
              <div className="card-body">
                <img
                  src="../assets/images/background/premium.png"
                  alt="bus"
                  style={{ height: "34px" }}
                />
                <div className="mt-2">
                  <h5>Limited Access</h5>
                  <p className="fs-6 mb-0">
                    <strong>Bus</strong>.Vanilla C1
                  </p>
                  <small>
                    &copy; Copyright 2024 {" "}
                    <Link
                      target="_blank"
                      to="https://www.linkedin.com/in/ndfranz"
                    >
                      NDFranz
                    </Link>
                    ; All rights reserved.
                  </small>
                </div>
              </div>
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    </div>
  );
}
