import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { useAuth } from "./context";
import PrivateRoute from "./privates-routes";
import Home from "./app/Home";
import Celling from "./app/Celling";
import CellingRepport from "./app/CellingRepport";
import AprovRepport from "./app/AprovRepport";
import Category from "./app/Category";
import SubCategory from "./app/SubCategory";
import Produces from "./app/Produces";
import PayementMethods from "./app/PayementMethods";
import Roles from "./app/Roles";
import Permissions from "./app/Permissions";
import Users from "./app/Users";
import MenuItem from "./app/MenuItem";
import Login from "./app/Login";
import NotFound from "./app/404";
import Profile from "./app/Profile";
import NotAllow from "./app/NotAllow";
import Loader from "./layouts/Loader";

const AppRoutes = () => {
  const { menuData } = useAuth();
  const [menuDataToUse, setMenuDataToUse] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [routesReady, setRoutesReady] = useState(false);

  const menuDataFetch = async () => {
    try {
      setLoading(true);
      const data = await menuData();
      setMenuDataToUse(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setMenuDataToUse([]);
      console.error("API Request Error (/menu-items):", error);
    }
  };

  useEffect(() => {
    menuDataFetch();
  }, []);

  useEffect(() => {
    const newRoutes = [];
    if (menuDataToUse.length === 0) {
      setRoutesReady(true);
      return;
    }
    menuDataToUse.forEach((item) => {
      if (item.path) {
        newRoutes.push({ path: item.path, element: <PrivateRoute>{getComponent(item.path)}</PrivateRoute> });
      }
      if (item.submenus) {
        item.submenus.forEach((submenu) => {
          newRoutes.push({ path: submenu.path, element: <PrivateRoute>{getComponent(submenu.path)}</PrivateRoute> });
        });
      }
    });

    setRoutes(newRoutes);
    setRoutesReady(true);
  }, [menuDataToUse]);

  const getComponent = (path) => {
    switch (path) {
      case "/":
        return <Home />;
      case "/celling":
        return <Celling />;
      case "/celling-repport":
        return <CellingRepport />;
      case "/aprov-repport":
        return <AprovRepport />;
      case "/category":
        return <Category />;
      case "/sub-category":
        return <SubCategory />;
      case "/produces":
        return <Produces />;
      case "/payements-methodes":
        return <PayementMethods />;
      case "/roles":
        return <Roles />;
      case "/permissions":
        return <Permissions />;
      case "/users":
        return <Users />;
      default:
        return <NotFound />;
    }
  };

  if (loading || !routesReady) {
    return <Loader />;
  }

  return (
    <Routes>
      {routes.length > 0 ? (
        routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))
      ) : (
        <>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/*" element={<NotAllow />} />
        </>
      )}
      <Route path="/settings" element={<PrivateRoute><Profile /></PrivateRoute>} />
      <Route path="/login" element={<Login />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;