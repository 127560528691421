import React from 'react';

// Fonction pour calculer les 5 produits les moins vendus
const getLeastSoldProducts = (data) => {
  const productTotals = {};

  data.forEach((item) => {
    const { produce_id, celling_value, produce } = item;

    if (!productTotals[produce_id]) {
      productTotals[produce_id] = {
        name: produce.name,
        subCategory: produce.subCategory.sub_category_name,
        totalQuantity: 0,
      };
    }

    // Ajouter la quantité vendue
    productTotals[produce_id].totalQuantity += parseInt(celling_value, 10);
  });

  // Convertir en tableau, trier par quantité croissante et retourner les 5 premiers
  return Object.values(productTotals)
    .sort((a, b) => a.totalQuantity - b.totalQuantity)
    .slice(0, 5);
};

const LeastSoldProductsReport = ({ repportData }) => {
  const leastSoldProducts = getLeastSoldProducts(repportData);

  return (
    <table className="table text-nowrap table-bordered table-striped mb-0 table-centered table-sm">
      <thead className="table-warning">
        <tr>
          <th>Produit</th>
          <th>Catégorie</th>
          <th>Quantité Vendue</th>
        </tr>
      </thead>
      <tbody>
        {leastSoldProducts.length > 0 ? (
          leastSoldProducts.map((product, index) => (
            <tr key={index}>
              <td>{product.name}</td>
              <td>{product.subCategory}</td>
              <td>{product.totalQuantity}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={3}>
              <div className="w-100 d-flex justify-content-center align-items-center">
                <h5>Aucune donnée disponible</h5>
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default LeastSoldProductsReport;