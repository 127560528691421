import auth_background from "../assets/login-cover.jpg";
import sidbar_background from "../assets/sidbar-design.png";
import auth_icon from "../assets/auth.svg";
import logo_sid_bar from "../assets/sidbar-logo-allwhite.png";
import user from "../assets/user.png";
import userWhite from "../assets/user-white.png";
import qrcodegif from "../assets/qr-code.gif";

import partern from "../assets/parten.svg";
import category from "../assets/category.png";
import notFound from "../assets/file.png";
import emptyBox from "../assets/leaf.png";
import undraw_no_data from "../assets/undraw_no-data.svg";
import patern_background from "../assets/patern-background.svg";
import enteteRapport from "../assets/entete.png";
import workInProgress from "../assets/work-in-progress.png";
import pageNotFound from "../assets/undraw_page-not-found_6wni.svg";
import undrawApprove from "../assets/undraw_approve_jz6b.svg";

const Medias = {
  sidbar_background,
  auth_background,
  auth_icon,
  logo_sid_bar,
  user,
  userWhite,
  qrcodegif,
  partern,
  category,
  notFound,
  emptyBox,
  patern_background,
  undraw_no_data,
  enteteRapport,
  workInProgress,
  pageNotFound,
  undrawApprove,
};
export default Medias;
