import React from "react";
import Medias from "../global/Medias";

export default function AprovRepport() {
  return (
    <div className="app-content-area">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            {/* Page header */}
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h3 className="mb-0">
                {" "}
                <i className="bi bi-clipboard-data"></i> Rapport des
                approvisionnements
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="card d-flex justify-content-center align-items-center p-5"
            style={{ height: "calc(100vh - 233px)" }}
          >
            <img
              src={Medias.workInProgress}
              alt="coming soon"
              style={{
                width: "144px",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
