import React, { useCallback, useContext, useEffect, useState } from "react";
import Loader from "./layouts/Loader";
import serverConfiguration from "./app.config.json";
import axios from "axios";

const SERVER_URL =
  serverConfiguration.env === "dev"
    ? serverConfiguration.backend.dev.SERVER
    : serverConfiguration.backend.production.SERVER;

const AuthContext = React.createContext();

export const useAuth = () => useContext(AuthContext);

export function AuthProvider({ children }) {
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const [userCredentials, setUserCredentials] = useState(null);

  /**
   * Helper pour récupérer le token utilisateur.
   */
  const getUserToken = () => JSON.parse(sessionStorage.getItem("user"));

  /**
   * Effectue une requête API avec le token d'autorisation.
   */
  const apiRequest = async (url, method = "GET", data = null) => {
    try {
      const token = getUserToken();
      const config = {
        method,
        url: `${SERVER_URL}${url}`,
        headers: { Authorization: `Bearer ${token}` },
        data,
      };
      const response = await axios(config);
      return response.data;
    } catch (error) {
      console.error(`API Request Error (${url}):`, error); 
      return []
    }
  };
  const apiRequestAuth = async (url, method = "GET", data = null) => {
    try {
      const token = getUserToken();
      const config = {
        method,
        url: `${SERVER_URL}${url}`,
        headers: { Authorization: `Bearer ${token}` },
        data,
      };
      const response = await axios(config);
      return response;
    } catch (error) {
      console.error(`API Request Error (${url}):`, error);
      return { status: error.status, message: error?.message }
    }
  };

  /**
   * Changer le nom d'utilisateur.
   */
  const changeUserName = (obj) => apiRequest("/change-username", "POST", obj);

  /**
   * Changer le mot de passe.
   */
  const changePassword = (obj) => apiRequest("/change-password", "POST", obj);

  /**
   * Valider le nom d'utilisateur.
  */
  const validateUsername = (obj) =>
    axios.post(`${SERVER_URL}/validate-username`, obj).then((res) => res.data);

  /**
   * GET MENU.
  */
  const menuData = () => apiRequest("/menu-items", "GET");

  /**
   * Valider le mot de passe et connecter l'utilisateur.
   */
  const validatePassword = async (obj) => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/validate-password`,
        obj
      );
      const token = response.data.token;
      sessionStorage.setItem("user", JSON.stringify(token));
      setCurrentUser(token);
      await onAuthStateChanged();

      return response.data.is_first_attemps_login
        ? response.data
        : { message: "Compte accepté" };
    } catch (error) {
      console.error("Validate Password Error:", error);
      throw error;
    }
  };

  /**
   * Déconnexion de l'utilisateur.
   */

  const logout = useCallback(async () => {
    setLoading(true);
    try {
      // Appeler l'API pour gérer la déconnexion côté serveur
      let res = await apiRequestAuth("/auth-state-logout", "GET");
      console.log("LOGOUT =>", res);
      // Nettoyer le stockage local et réinitialiser l'utilisateur actuel
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("currentUser");
      setCurrentUser(null);
    } catch (error) {
      console.error("Logout error:", error);
    } finally {
      setLoading(false); // Toujours désactiver le chargement, même en cas d'erreur
    }
  }, [setCurrentUser]);


  /**
   * Surveille l'état d'authentification.
   */
  const onAuthStateChanged = useCallback(async () => {
    try {
      setLoading(true); // Activer le chargement avant de vérifier l'état
      const token = getUserToken();
      // Si aucun token n'est présent, l'utilisateur est considéré comme déconnecté
      if (!token) {
        setCurrentUser(null);
        setUserCredentials(null); // Réinitialiser les informations utilisateur
        return;
      }

      // Faire une requête pour valider l'état d'authentification
      const data = await apiRequestAuth("/auth-state");
      // Mettre à jour les informations de l'utilisateur dans sessionStorage
      // console.log(data)
      if (data.status !== 200) {
        await logout();
        return;
      }
      sessionStorage.setItem(
        "currentUser",
        JSON.stringify(data.data.userCredentials)
      );
      setUserCredentials(data.data.userCredentials);
      setCurrentUser(token);
    } catch (error) {
      console.error("Error in onAuthStateChanged:", error);
      // Si une erreur survient, se déconnecter uniquement si un utilisateur est connecté
      if (currentUser) {
        await logout();
      }
    } finally {
      setLoading(false); // Désactiver le chargement après l'exécution
    }
  }, [logout, currentUser]);


  useEffect(() => {
    onAuthStateChanged();
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <AuthContext.Provider
      value={{
        currentUser,
        userCredentials,
        validatePassword,
        validateUsername,
        menuData,
        logout,
        changeUserName,
        changePassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}