import { Toaster } from "react-hot-toast";
import axios from "axios";
import serverConfiguration from "../../app.config.json";

const SERVER_URL =
  serverConfiguration.env === "dev"
    ? serverConfiguration.backend.dev.SERVER
    : serverConfiguration.backend.production.SERVER;

export const CreateNewSale = async (saleData, currentUser) => {
  const url = `${SERVER_URL}/new-sale`; // Utilisez l'URL serveur dynamique

  return new Promise(async (resolve, reject) => {
    try {
      const request = await axios.post(url, saleData, {
        headers: { Authorization: `Bearer ${currentUser}` },
        timeout: 10000, // Définir le délai d'attente à 10 secondes
      });
      resolve(request.data.message); // Résoudre le message de succès de la réponse API
    } catch (error) {
      console.error("Erreur lors de la création de la vente :", error);
      reject(
        error.response?.data?.message ||
          "Une erreur est survenue, veuillez réessayer."
      );
    }
  });
};

export const SaleToaster = () => <Toaster />; // Exporter le composant Toaster pour les notifications
