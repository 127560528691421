import React from "react";
import { Link } from "react-router-dom";
import Medias from "../global/Medias";
import { useAuth } from "../context";
import ThemeSwitcher from "../global/ThemeSwitcher";

export default function Header({ isToggle, setIsToggle }) {
  const { logout, userCredentials } = useAuth();
  // const navigate = useNavigate();

  // const handleLogout = () => {
  //   logout();
  //   return navigate("/login", { replace: true });
  // }
  return (
    <div className="header" style={{ transition: "all 1s ease" }}>
      {/* navbar */}
      <div
        className="navbar-custom navbar navbar-expand-lg"
        style={{ transition: "all .3s ease" }}
      >
        <div className="container-fluid px-0">
          <button
            type="button"
            title="Masquer/Aficher la bar de menu"
            id="nav-toggle"
            onClick={() => {
              setIsToggle(!isToggle);
            }}
            className="ms-md-0 me-0 me-lg-3 text-start btn btn-text btn-icon rounded-circle"
          >
            <i className="bi bi-layout-sidebar-inset" />
          </button>

          <ul className="navbar-nav navbar-right-wrap ms-lg-auto d-flex nav-top-wrap align-items-center ms-4 ms-lg-0">
            <li>
              <ThemeSwitcher />
            </li>
            <li className="dropdown ms-2">
              <Link
                className="rounded-circle"
                href="#!"
                role="button"
                id="dropdownUser"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="avatar avatar-md avatar-indicators avatar-online">
                  <img
                    alt="avatar"
                    src={Medias.user}
                    className="rounded-circle"
                  />
                </div>
              </Link>
              <div
                className="dropdown-menu dropdown-menu-end"
                aria-labelledby="dropdownUser"
              >
                <div
                  className="px-4 pb-0 pt-2"
                  title={"Connecté en tant que " + userCredentials.username}
                >
                  <div className="lh-1">
                    <h5 className="mb-1">{userCredentials.username}</h5>
                  </div>
                  <div className="dropdown-divider mt-3 mb-2" />
                </div>

                <ul className="list-unstyled">
                  <li>
                    <Link
                      className="dropdown-item d-flex align-items-center"
                      to="/settings"
                    >
                      <i
                        className="me-2 icon-xxs icofont-duotone icofont-manage-user"
                        data-feather="user"
                      />
                      Profile
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/login"
                      onClick={() => logout()}
                    >
                      <i
                        className="me-2 icon-xxs icofont-duotone icofont-sign-out"
                        data-feather="power"
                      />
                      Sign Out
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
