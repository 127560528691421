import React from "react";

const SearchBarProduce = React.memo(
  ({ handleSearch, isLoadingSearch, search, setSearch }) => (
    <form onSubmit={handleSearch}>
      <div className="col-12 d-flex gap-3">
        <div className="input-group">
          <input
            className="form-control rounded-3 bg-transparent"
            type="text"
            placeholder="Désignation, sous-catégorie, ou catégorie"
            value={search}
            name="search"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <button
          disabled={isLoadingSearch}
          type="submit"
          className="btn btn-outline-success"
        >
          Rechercher
        </button>
      </div>
    </form>
  )
);

export default SearchBarProduce;
