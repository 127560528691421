import React from "react";
import { Link } from "react-router-dom";
import Medias from "../global/Medias";

export default function NotFound() {
  return (
    <main className="container min-vh-100 d-flex justify-content-center align-items-center">
      <div className="position-absolute end-0 top-0 p-8">
        <div className="dropdown">
          <button
            className="btn btn-ghost btn-icon rounded-circle"
            type="button"
            aria-expanded="false"
            data-bs-toggle="dropdown"
            aria-label="Toggle theme (auto)"
          >
            <i className="bi theme-icon-active"></i>
            <span className="visually-hidden bs-theme-text">Toggle theme</span>
          </button>
          <ul
            className="dropdown-menu dropdown-menu-end shadow"
            aria-labelledby="bs-theme-text"
          >
            <li>
              <button
                type="button"
                className="dropdown-item d-flex align-items-center"
                data-bs-theme-value="light"
                aria-pressed="false"
              >
                <i className="bi theme-icon bi-sun-fill"></i>
                <span className="ms-2">Light</span>
              </button>
            </li>
            <li>
              <button
                type="button"
                className="dropdown-item d-flex align-items-center"
                data-bs-theme-value="dark"
                aria-pressed="false"
              >
                <i className="bi theme-icon bi-moon-stars-fill"></i>
                <span className="ms-2">Dark</span>
              </button>
            </li>
            <li>
              <button
                type="button"
                className="dropdown-item d-flex align-items-center active"
                data-bs-theme-value="auto"
                aria-pressed="true"
              >
                <i className="bi theme-icon bi-circle-half"></i>
                <span className="ms-2">Auto</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="text-center">
            <div className="mb-3">
              <img
                style={{ width: "400px" }}
                src={Medias.pageNotFound}
                alt="Image"
                className="img-fluid"
              />
            </div>
            <h1 className="display-4">Oops! the page not found.</h1>
            <p className="mb-4">
              Or simply leverage the expertise of our consultation team.
            </p>
            <Link to="/" className="btn btn-primary">
              Go Home
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
}
