import React from "react";
import { generateUniqueId } from "../../global/UniqueID";
import serverConfiguration from "../../app.config.json";

const PermissionsTable = ({
  permissions,
  values,
  setValues,
  setShowForm,
  setIsUpdateData,
  handleRemoveTheRole,
}) => {
  const renderActionCheckboxes = (permissionId, actions) => {
    const actionLabels = {
      C: "Ajout",
      R: "Lecture",
      U: "Modification",
      D: "Suppression",
    };

    // Map to handle existing actions and ensure all actionLabels are rendered
    const fullActionsList = Object.keys(actionLabels).map((action) => {
      const uid = generateUniqueId(8);
      const isChecked = actions.includes(action); // Default checked if the action exists in the array

      return (
        <div className="form-check form-check-inline" key={uid}>
          <input
            className="form-check-input"
            type="checkbox"
            id={`check_permission_${uid}`}
            checked={isChecked}
            readOnly
            disabled
          />
          <label
            className="form-check-label"
            htmlFor={`check_permission_${uid}`}
          >
            {actionLabels[action]}
          </label>
        </div>
      );
    });
    return fullActionsList;
  };

  const groupedPermissions = permissions.reduce((acc, item) => {
    const { name } = item;
    if (!acc[name]) {
      acc[name] = [];
    }
    acc[name].push(item);
    return acc;
  }, {});

  const returnZoneName = (e) =>
    serverConfiguration.tables.filter((z) => z.value === e)[0];
  return (
    <table className="table table-bordered table-sm">
      <thead>
        <tr>
          <th scope="col">Role</th>
          <th scope="col">Actions autorisées</th>
          <th scope="col">Zone d'accès</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(groupedPermissions).map((name) => {
          const rows = groupedPermissions[name];
          return rows.map((item, index) => {
            return (
              <tr key={item.permission_id}>
                {index === 0 && (
                  <td
                    rowSpan={rows.length}
                    style={{ verticalAlign: "middle", textAlign: "center" }}
                    className="h4"
                  >
                    {item.name}
                  </td>
                )}
                <td>
                  <div className="d-flex gap-2">
                    {renderActionCheckboxes(item.permission_id, item.actions)}
                  </div>
                </td>
                <td>{returnZoneName(item.table_name).name}</td>
                <td>
                  <div className="">
                    <button
                      title="Editer les information de cet emplacement"
                      className="btn btn-text btn-sm"
                      onClick={() => {
                        setValues({
                          actions: item.actions,
                          table_name: item.table_name,
                          role_id: item.role_id,
                          permission_id: item.permission_id,
                        });
                        setShowForm(true);
                        setIsUpdateData(true);
                      }}
                    >
                      <i className="icofont-edit"></i>
                    </button>
                    <button
                      title="Supprimer cet emplacement"
                      className="btn btn-text btn-sm"
                      onClick={() => handleRemoveTheRole(item.permission_id)}
                    >
                      <i className="icofont-delete"></i>
                    </button>
                  </div>
                </td>
              </tr>
            );
          });
        })}
      </tbody>
    </table>
  );
};

export default PermissionsTable;
