import React from "react";
import { Link } from "react-router-dom";

function CurrentDate({ handleSetTodayDate }) {
  return (
    <Link
      to=""
      className="btn btn-primary ms-2 texttooltip"
      data-template="dateToday"
      onClick={handleSetTodayDate}
    >
      <i className="bi bi-clipboard-data"></i> Journalière
      <div id="dateToday" className="d-none">
        <span>Rapport journalière</span>
      </div>
    </Link>
  );
}

export default CurrentDate;
