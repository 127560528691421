// Fonction pour agréger les données
const aggregateData = (data) => {
  const report = {};

  data.forEach((item) => {
    const { produce_id, celling_value, celling_discount, produce } = item;
    if (!report[produce_id]) {
      report[produce_id] = {
        name: produce.name,
        subCategory: produce.subCategory.sub_category_name,
        price: parseFloat(produce.price),
        totalQuantity: 0,
        totalRevenue: 0,
        totalDiscount: 0,
      };
    }

    // Mettre à jour les valeurs
    report[produce_id].totalQuantity += parseInt(celling_value, 10);
    report[produce_id].totalRevenue += parseFloat(produce.price) * parseInt(celling_value, 10);
    report[produce_id].totalDiscount += parseFloat(celling_discount || 0);
  });

  return Object.values(report); // Retourner une liste des produits agrégés
};

const ProduceReport = ({ repportData, sales }) => {
  const aggregatedData = aggregateData(repportData);

  return aggregatedData.map((data, index) => (
    <tr key={index}>
      <td>{data.name}</td>
      <td className="money-font">{data.price.toLocaleString()}</td>
      <td>{data.totalQuantity}</td>
      <td className="money-font">{data.totalRevenue.toLocaleString()}</td>
      <td className="money-font">{data.totalDiscount.toLocaleString()}</td>
      <td>{data.subCategory}</td>
    </tr>
  ));
};

export default ProduceReport;
