import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import TableLoader from "../../layouts/TableLoader";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const getCashierPerformance = (sales) => {
  const performance = {};

  sales.forEach((sale) => {
    const { cashier_id, total_amount, discount } = sale;

    if (!performance[cashier_id]) {
      performance[cashier_id] = {
        totalRevenue: 0,
        totalDiscount: 0,
        transactionCount: 0,
      };
    }

    performance[cashier_id].totalRevenue += parseFloat(total_amount);
    performance[cashier_id].totalDiscount += parseFloat(discount || 0);
    performance[cashier_id].transactionCount += 1;
  });

  return performance;
};

const CashierPerformanceChart = ({ sales, cashiers }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [performanceData, setPerformanceData] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      const aggregatedData = getCashierPerformance(sales);
      setPerformanceData(aggregatedData);
      setIsLoading(false);
    }, 1000);
  }, [sales]);

  if (isLoading) {
    return (
      <div className="w-100 d-flex justify-content-center align-items-center shadow-0">
        <TableLoader />
      </div>
    );
  }

  const labels = Object.keys(performanceData).map(
    (id) =>
      cashiers.find((cashier) => cashier.id === parseInt(id, 10))?.name ||
      `Caissier ${id}`
  );

  const revenueData = Object.values(performanceData).map(
    (data) => data.totalRevenue
  );
  const discountData = Object.values(performanceData).map(
    (data) => data.totalDiscount
  );
  const transactionData = Object.values(performanceData).map(
    (data) => data.transactionCount
  );

  const data = {
    labels,
    datasets: [
      {
        label: "Recette Totale (FCFA)",
        data: revenueData,
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        yAxisID: "y",
      },
      {
        label: "Remises Totales (FCFA)",
        data: discountData,
        backgroundColor: "rgba(255, 99, 132, 0.6)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
        yAxisID: "y",
        type: "bar",
      },
      {
        label: "Nombre de Transactions",
        data: transactionData,
        borderColor: "rgba(54, 162, 235, 1)",
        backgroundColor: "rgba(54, 162, 235, 0.4)",
        borderWidth: 2,
        tension: 0.4,
        type: "line",
        yAxisID: "y",
      },
    ],
  };

  const config = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Performance des Caissiers",
      },
      tooltip: {
        mode: "index",
        intersect: false,
      },
      legend: {
        position: "top",
      },
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: "Caissiers",
        },
      },
      y: {
        display: true,
        beginAtZero: true,
        title: {
          display: true,
          text: "Valeurs (FCFA)",
        },
      },
    },
  };

  return <Chart type="bar" data={data} options={config} />;
};

export default CashierPerformanceChart;
