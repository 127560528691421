import React, { memo } from "react";

const DetailsViewProduce = memo(
  ({
    dataForDetail,
    aprov,
    handleInputChange,
    handleHideDetails,
    Medias,
    createProductMovement,
  }) => {
    // Vérification si les données sont disponibles
    if (!dataForDetail) return null;

    return (
      <form
        className="row mt-5 needs-validation ndf-transimssion"
        onSubmit={(e) => {
          e.preventDefault();
          createProductMovement();
        }}
      >
        <div className="col-12 d-md-flex">
          {/* Section de l'image */}
          <div
            className="col-md-6 col-12 d-flex p-2 rounded"
            style={{
              height: "400px",
              width: "400px",
              background: `url(${Medias.partern})`,
            }}
          >
            <img
              src={
                dataForDetail.cover === ""
                  ? Medias.category
                  : dataForDetail.cover
              }
              className="rounded"
              style={{ objectFit: "cover", height: "100%", width: "100%" }}
              alt="Image du produit"
            />
          </div>

          {/* Section des détails */}
          <div className="col ps-md-10 ps-0">
            <h3>{dataForDetail.name}</h3>
            <div>
              <span className="me-2 text-dark">
                <i className="bi bi-tags text-success"></i>
              </span>
              {dataForDetail.sub_category_name}
            </div>
            <hr className="my-3" />
            <div className="d-flex justify-content-between">
              <h3 className="mb-1 col-md-6 col-12">
                <span className="text-muted">
                  <i className="bi bi-piggy-bank"></i> Prix
                </span>
                <span className="text-danger"> {dataForDetail.price}</span>
              </h3>
              <h3 className="mb-1">
                <span className="text-muted">
                  <i className="bi bi-inboxes"></i> Stock valide:
                </span>
                <span className="text-danger">
                  {" "}
                  {dataForDetail.current_stock}
                </span>
              </h3>
            </div>
            <div className="border-top py-4">
              <div className="border d-inline-block px-2 py-1 rounded-pill mb-3">
                <span className="text-dark">
                  Description{" "}
                  <i className="bi bi-star-fill text-success fs-6"></i>
                </span>
              </div>
              <p>
                {dataForDetail.description === ""
                  ? "Aucune description disponible"
                  : dataForDetail.description}
              </p>
              <span>12/12/24</span>
            </div>
            <hr />
            <div className="form-group col-md-8 col-12">
              <label htmlFor="aprovValue">Valeur d'approvisionnement</label>
              <input
                type="number"
                id="aprovValue"
                className="form-control"
                value={aprov}
                onChange={handleInputChange}
                min={1}
              />
            </div>
          </div>
        </div>

        {/* Boutons d'action */}
        <div className="col-12 justify-content-end mt-5 d-flex gap-3">
          <button
            className="btn btn-outline-secondary"
            type="button"
            title="Revenir au résultat de recherche"
            onClick={handleHideDetails}
          >
            <i className="bi bi-arrow-clockwise"></i> Annuler
          </button>
          <button
            className="btn btn-success"
            type="submit"
            title="Enregistrer le stock"
          >
            <i className="bi bi-save"></i> Valider
          </button>
        </div>
      </form>
    );
  }
);

export default DetailsViewProduce;
