import React from "react";
import ThemeSwitcher from "../global/ThemeSwitcher";
import config from "../app.config.json";
import { useAuth } from "../context";
import { useNavigate } from "react-router-dom";
import Medias from "../global/Medias";

export default function NotAllow() {
  const { logout } = useAuth();
  const navigate = useNavigate();
  return (
    <main className="py-12" style={{ height:"100vh", background: `url(${Medias.patern_background})` }}>
      <div style={{ position: "absolute", bottom: "1rem", right: "2rem" }}>
        <ThemeSwitcher />
        <small className="text-secondary">
          {config.app_name} V{config.version}, C{config.client}
        </small>
      </div>
      <div className="position-absolute end-0 top-0 p-8">
        <div className="dropdown">
          <button
            className="btn btn-ghost btn-icon rounded-circle"
            type="button"
            aria-expanded="false"
            data-bs-toggle="dropdown"
            aria-label="Toggle theme (auto)"
          >
            <i className="bi theme-icon-active"></i>
            <span className="visually-hidden bs-theme-text">Toggle theme</span>
          </button>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-12 mb-lg-16 mb-8">
            <div className="mb-12">
              <h1>{config.app_name}</h1>
              <div className="d-flex justify-content-center">
                <p className="w-75">
                  Bienvenue sur <strong>Bus.vanilla</strong>, votre solution
                  complète pour la gestion des stocks et des ventes. Nous
                  travaillons constamment à améliorer votre expérience.
                </p>
              </div>
            </div>
            <div>
              <img src={Medias.undrawApprove} height="300" alt="Image" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="offset-xl-1 col-xl-10 col-12">
            <div className="row">
              <div className="col-md-4 col-12">
                <div className="mb-4 mb-lg-0">
                  <i
                    className="mb-4 text-primary icon-md"
                    data-feather="box"
                  ></i>
                  <h4>Pourquoi utiliser Bus.vanilla ?</h4>
                  <p className="mb-0">
                    Bus.vanilla simplifie la gestion de vos stocks et ventes
                    avec des outils intuitifs, adaptés à tous les secteurs
                    d’activité.
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="mb-4 mb-lg-0">
                  <i
                    className="mb-4 text-primary icon-md"
                    data-feather="trending-up"
                  ></i>
                  <h4>Quels sont les avantages ?</h4>
                  <p className="mb-0">
                    Suivi des stocks en temps réel, génération de rapports
                    détaillés, gestion optimisée des ventes et bien plus encore
                    !
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="mb-4 mb-lg-0">
                  <i
                    className="mb-4 text-primary icon-md"
                    data-feather="help-circle"
                  ></i>
                  <h4>Besoin d'assistance ?</h4>
                  <p>
                    Notre équipe est là pour vous aider. En cas d'urgence,
                    contactez-nous ou changez de compte si nécessaire.
                  </p>
                  <button
                    className="text-inherit btn btn-text"
                    type="button"
                    onClick={() => {
                      logout();
                      navigate("/login");
                    }}
                  >
                    Se connecter avec un autre compte
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
