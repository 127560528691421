import React, { useState } from "react";
import TableLoader from "../layouts/TableLoader";
import CurrentDate from "./btns/CurrentDate";
import OtherDateModal from "./btns/OtherDateModal";
import serverConfiguration from "../app.config.json";
import toast, { Toaster } from "react-hot-toast";
import { useAuth } from "../context";
import axios from "axios";
import ProduceReport from "./inc/ProduceReport";
import { formatDateToYYYYMMDDH } from "../global/DateFormater";
import Medias from "../global/Medias";
import TopProductsReport from "./inc/TopProductsReport";
import LeastSoldProductsReport from "./inc/LeastSoldProductsReport";
import ChartBtn from "./btns/ChartBtn";
import CashierPerformanceChart from "./inc/CashierPerformanceChart";
import DownloadReport from "./inc/DownloadReport";
const SERVER_URL =
  serverConfiguration.env === "dev"
    ? serverConfiguration.backend.dev.SERVER
    : serverConfiguration.backend.production.SERVER;

export default function CellingRepport() {
  const { currentUser } = useAuth();
  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });
  const [isLoadingDbData, setIsLoadingDbData] = useState(false);
  const [isShowChart, setIsShowChart] = useState(false);
  const [repportData, setRepportData] = useState({
    salesMovements: [],
    sales: [],
    cashiers: [],
  });

  const calculateTotals = (sales) => {
    let totalRevenue = 0;
    let totalDiscount = 0;

    sales.forEach(({ total_amount, discount }) => {
      totalRevenue += parseFloat(total_amount);
      totalDiscount += parseFloat(discount || 0);
    });

    return { totalRevenue, totalDiscount };
  };

  const { totalRevenue, totalDiscount } = calculateTotals(repportData.sales);

  const handleSubmitRepportGet = async (obj) => {
    try {
      setIsLoadingDbData(true);
      console.log(obj);
      let dataRange = await axios.post(
        `${SERVER_URL}/repport-aprov`, // Use the appropriate URL
        obj, // Request body should be the second parameter
        {
          headers: { Authorization: `Bearer ${currentUser}` }, // Headers should be the third parameter
        }
      );
      console.log(dataRange.data);
      setRepportData(dataRange.data);
    } catch (error) {
      console.log(error);
      toast.error("Erreur de chargement des données", {
        position: "bottom-right",
      });
    } finally {
      setIsLoadingDbData(false);
    }
  };

  const handleDateRange = () => {
    handleSubmitRepportGet(dateRange);
  };

  const handleSetTodayDate = () => {
    const today = new Date();
    const startDate = new Date(today.setHours(0, 0, 0, 0)).toISOString();
    const endDate = new Date(today.setHours(23, 59, 59, 999)).toISOString();
    handleSubmitRepportGet({ startDate, endDate });
  };

  return (
    <div className="app-content-area">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            {/* Page header */}
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h3 className="mb-0">
                {" "}
                <i className="bi bi-clipboard-data"></i> Rapport de vente
              </h3>
            </div>
            {/* {!isShowChart && repportData?.salesMovements.length > 0 && (
              <PreviewPDF
                repportData={repportData.salesMovements}
                dateRange={dateRange}
                totalRevenue={totalRevenue}
                totalDiscount={totalDiscount}
              />
            )} */}
            {!isShowChart ? (
              <div className="row animate-right">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header d-md-flex justify-content-between border-0">
                      <h4 className="mb-0">Ventes</h4>
                      <div>
                        {!isShowChart &&
                          repportData?.salesMovements.length > 0 && (
                            <DownloadReport
                              repportData={repportData.salesMovements}
                              dateRange={dateRange}
                              totalRevenue={totalRevenue}
                              totalDiscount={totalDiscount}
                            />
                          )}
                        <OtherDateModal
                          setDateRange={setDateRange}
                          dateRange={dateRange}
                          handleDateRange={handleDateRange}
                        />
                        <CurrentDate handleSetTodayDate={handleSetTodayDate} />
                      </div>
                    </div>

                    <div className="card-body mb-5">
                      <div className="table-responsive table-card px-5">
                        <table className="table text-nowrap table-bordered table-striped mb-0 table-centered table-sm">
                          <thead className="table-white">
                            <tr>
                              <th>Article</th>
                              <th>Prix Unitaire</th>
                              <th>Quantité Vendue</th>
                              <th>Recette Totale</th>
                              <th>Remise</th>
                              <th>Catégorie</th>
                            </tr>
                          </thead>
                          <tbody>
                            {isLoadingDbData ? (
                              <tr>
                                <td colSpan={6}>
                                  <div className="w-100 d-flex justify-content-center align-item-center">
                                    <TableLoader />
                                  </div>
                                </td>
                              </tr>
                            ) : repportData?.salesMovements.length > 0 ? (
                              <ProduceReport
                                repportData={repportData.salesMovements}
                                sales={repportData.sales}
                              />
                            ) : (
                              <tr>
                                <td colSpan={6}>
                                  <div className="w-100 d-flex flex-column justify-content-center align-item-center gap-3 py-5">
                                    <img
                                      alt="not-found"
                                      src={Medias.undraw_no_data}
                                      style={{ height: "144px" }}
                                    />
                                    <div className="text-center">
                                      <h5>Aucune donnée disponible</h5>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                          <tfoot className="table-white">
                            <tr>
                              <td colSpan={6}>
                                <br />
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={3} rowSpan={2}>
                                <div>
                                  <strong>RAPPORT DE VENTE </strong>
                                  <span>
                                    Du:{" "}
                                    <span>
                                      {formatDateToYYYYMMDDH(
                                        dateRange.startDate
                                      )}
                                    </span>
                                    {" | "}
                                    Au:{" "}
                                    <span>
                                      {formatDateToYYYYMMDDH(dateRange.endDate)}
                                    </span>
                                  </span>
                                </div>
                              </td>
                              <td>
                                <strong className="money-font">
                                  {totalRevenue.toLocaleString()}{" "}
                                  <small>FCFA</small>
                                </strong>
                              </td>
                              <td className="money-font" colSpan={2}>
                                <strong>
                                  {totalDiscount.toLocaleString()}{" "}
                                  <small>FCFA</small>
                                </strong>
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>

                    {isLoadingDbData ? (
                      <></>
                    ) : (
                      <div className="row px-5">
                        <div className="col-md-6 col-12">
                          <h4 className="mb-3">Top Produits</h4>
                          <TopProductsReport
                            repportData={repportData.salesMovements}
                          />
                        </div>
                        <div className="col-xl-6 col-12">
                          <h4 className="mb-3">Produits les moins vendus</h4>
                          <LeastSoldProductsReport
                            repportData={repportData.salesMovements}
                          />
                        </div>
                      </div>
                    )}

                    <div className="card-footer border-0"></div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header d-md-flex justify-content-between">
                      <h4 className="mb-0">Graphique</h4>
                    </div>
                    <div className="card-body chart-canvas-ndf d-flex">
                      {repportData?.salesMovements.length > 0 ? (
                        <CashierPerformanceChart
                          sales={repportData.sales}
                          cashiers={repportData.cashiers}
                        />
                      ) : (
                        <div className="w-100 d-flex justify-content-center align-item-center">
                          <img
                            alt="not-found"
                            src={Medias.undraw_no_data}
                            style={{ height: "144px" }}
                          />
                        </div>
                      )}
                    </div>
                    <div className="card-footer border-0"></div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <ChartBtn setIsShowChart={setIsShowChart} isShowChart={isShowChart} />
      <Toaster />
    </div>
  );
}
