import React from "react";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import {
  formatDateToYYYYMMDD,
  formatDateToYYYYMMDDH,
} from "../../global/DateFormater";
import oswald from "../../assets/Oswald.ttf";
import poppinsRegular from "../../assets/Poppins-Regular.ttf";
import Medias from "../../global/Medias";
import config from "../../app.config.json";
// Fonction pour agréger les données
const aggregateData = (data) => {
  const report = {};
  data.forEach((item) => {
    const { produce_id, celling_value, celling_discount, produce } = item;
    if (!report[produce_id]) {
      report[produce_id] = {
        name: produce.name,
        subCategory: produce.subCategory.sub_category_name,
        price: parseFloat(produce.price),
        totalQuantity: 0,
        totalRevenue: 0,
        totalDiscount: 0,
      };
    }

    // Mettre à jour les valeurs
    report[produce_id].totalQuantity += parseInt(celling_value, 10);
    report[produce_id].totalRevenue +=
      parseFloat(produce.price) * parseInt(celling_value, 10);
    report[produce_id].totalDiscount += parseFloat(celling_discount || 0);
  });

  return Object.values(report); // Retourner une liste des produits agrégés
};

const getTopProducts = (data) => {
  const productTotals = {};

  data.forEach((item) => {
    const { produce_id, celling_value, produce } = item;

    if (!productTotals[produce_id]) {
      productTotals[produce_id] = {
        name: produce.name,
        subCategory: produce.subCategory.sub_category_name,
        totalQuantity: 0,
      };
    }

    productTotals[produce_id].totalQuantity += parseInt(celling_value, 10);
  });

  return Object.values(productTotals)
    .sort((a, b) => b.totalQuantity - a.totalQuantity)
    .slice(0, 5);
};

const getLeastSoldProducts = (data) => {
  const productTotals = {};

  data.forEach((item) => {
    const { produce_id, celling_value, produce } = item;

    if (!productTotals[produce_id]) {
      productTotals[produce_id] = {
        name: produce.name,
        subCategory: produce.subCategory.sub_category_name,
        totalQuantity: 0,
      };
    }

    productTotals[produce_id].totalQuantity += parseInt(celling_value, 10);
  });

  return Object.values(productTotals)
    .sort((a, b) => a.totalQuantity - b.totalQuantity)
    .slice(0, 5);
};

Font.register({
  family: "Oswald",
  src: oswald,
});

Font.register({
  family: "Poppins",
  src: poppinsRegular,
});

// Styles pour le PDF
const styles = StyleSheet.create({
  page: {
    padding: 15,
    fontSize: 8,
    fontFamily: "Poppins",
  },
  sectionTitleTable: {
    marginBottom: 0,
    fontSize: 11,
    color: "#624bff",
  },
  sectionTitle: {
    marginBottom: 0,
    textAlign: "center",
    fontSize: 11,
  },
  textPrimary: {
    color: "#624bff",
  },
  section: {
    marginBottom: 10,
    textAlign: "center",
    fontSize: 10,
    padding: 5,
    backgroundColor: "rgba(98, 75, 255, 0.1)",
    width: "70%",
    marginRight: "auto",
    marginLeft: "auto",
    borderRadius: 50,
  },
  imageTitle: {
    width: "100%",
    height: "auto",
    marginBottom: 10,
  },
  table: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 10,
    borderWidth: 0.5,
    borderColor: "#D0E4F5",
    borderStyle: "solid",
    borderRadius: 5,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    flex: 1,
    padding: 5,
    borderWidth: 0.5,
    borderColor: "#D0E4F5",
    textAlign: "left",
  },
  tableHeader: {
    backgroundColor: "#624bff",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    color: "#FFFFFF",
    fontWeight: "bold",
  },
  totalTable: {
    width: "40%",
    borderWidth: 0.5,
    borderColor: "#D0E4F5",
    borderStyle: "solid",
    padding: 5,
    backgroundColor: "#f8f9fa",
    borderRadius: 5,
  },
  totalRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 3,
  },
  footer: {
    marginTop: 20,
    textAlign: "center",
    fontSize: 10,
  },
  marge: {
    position: "absolute",
    bottom: 10,
    right: 15,
    fontSize: 7,
    fontFamily: "Oswald",
    textAlign: "right",
  },
  margeLeft: {
    position: "absolute",
    bottom: 10,
    left: 15,
    fontSize: 7,
    fontFamily: "Oswald",
    textAlign: "right",
    color: "#ffffff",
  },
  mt: {
    marginTop: 20,
  },
  tableHeaderDanger: {
    backgroundColor: "#624bff",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    color: "#FFFFFF",
    fontWeight: "bold",
  },
});

const PreviewPDFContent = ({
  repportData,
  dateRange,
  totalRevenue,
  totalDiscount,
}) => {
  const aggregatedData = aggregateData(repportData);
  const topProducts = getTopProducts(repportData);
  const leastSoldProducts = getLeastSoldProducts(repportData);

  return (
    <Document>
      <Page style={styles.page}>
        <Image style={styles.imageTitle} src={Medias.enteteRapport} />
        <Text style={styles.sectionTitle}>
          Rapport de vente des produits par sous-catégorie de produit
        </Text>
        <Text style={styles.section}>
          Du: {formatDateToYYYYMMDDH(dateRange.startDate)} | Au:{" "}
          {formatDateToYYYYMMDDH(dateRange.endDate)}
        </Text>
        <Text style={styles.sectionTitleTable}>Détails des produits</Text>
        <View style={styles.table}>
          <View style={[styles.tableRow, styles.tableHeader]}>
            <Text style={styles.tableCell}>Article</Text>
            <Text style={styles.tableCell}>Prix Unitaire</Text>
            <Text style={styles.tableCell}>Quantité Totale</Text>
            <Text style={styles.tableCell}>Recette Totale</Text>
            <Text style={styles.tableCell}>Sous-catégorie</Text>
          </View>
          {aggregatedData.length > 0 ? (
            aggregatedData.map((item, index) => (
              <View key={index} style={styles.tableRow}>
                <Text style={styles.tableCell}>{item.name}</Text>
                <Text style={styles.tableCell}>{item.price}</Text>
                <Text style={styles.tableCell}>{item.totalQuantity}</Text>
                <Text style={styles.tableCell}>{item.totalRevenue}</Text>
                <Text style={styles.tableCell}>{item.subCategory}</Text>
              </View>
            ))
          ) : (
            <View style={styles.tableRow}>
              <Text style={styles.tableCell} colSpan={6}>
                Aucune donnée disponible
              </Text>
            </View>
          )}
        </View>
        <View style={styles.totalTable}>
          <View style={styles.totalRow}>
            <Text>TOTAL REVENU :</Text>
            <Text style={styles.textPrimary}>{totalRevenue} FCFA</Text>
          </View>
          <View style={styles.totalRow}>
            <Text>TOTAL REMISE :</Text>
            <Text style={styles.textPrimary}>{totalDiscount} FCFA</Text>
          </View>
        </View>
        <Text style={[styles.sectionTitleTable, styles.mt]}>
          Top 5 des produits les plus vendus
        </Text>
        <View style={styles.table}>
          {topProducts.map((item, index) => (
            <View key={index} style={styles.tableRow}>
              <Text style={styles.tableCell}>{item.name}</Text>
              <Text style={styles.tableCell}>{item.subCategory}</Text>
              <Text style={styles.tableCell}>{item.totalQuantity}</Text>
            </View>
          ))}
        </View>
        <Text style={styles.sectionTitleTable}>
          Top 5 des produits les moins vendus
        </Text>
        <View style={styles.table}>
          {leastSoldProducts.map((item, index) => (
            <View key={index} style={styles.tableRow}>
              <Text style={styles.tableCell}>{item.name}</Text>
              <Text style={styles.tableCell}>{item.subCategory}</Text>
              <Text style={styles.tableCell}>{item.totalQuantity}</Text>
            </View>
          ))}
        </View>
        <View style={styles.marge}>
          <Text>{`Imprimé le : ${formatDateToYYYYMMDDH(new Date())}`}</Text>
          <Text>{config.app_name + " | V" + config.version}</Text>
        </View>
        <View style={styles.margeLeft}>
          <Text>{config.app_name + " | V" + config.version}</Text>
        </View>
      </Page>
    </Document>
  );
};

const PreviewPDFDownloadLink = ({
  repportData,
  dateRange,
  totalRevenue,
  totalDiscount,
}) => (
  <PDFDownloadLink
    className="btn btn-warning"
    document={
      <PreviewPDFContent
        repportData={repportData}
        dateRange={dateRange}
        totalRevenue={totalRevenue}
        totalDiscount={totalDiscount}
      />
    }
    fileName={`${config.repport}-RAPPORT-DU-${formatDateToYYYYMMDD(
      dateRange.startDate
    )}-${formatDateToYYYYMMDD(dateRange.endDate)}.pdf`}
  >
    {({ loading }) => (
      <>
        <i className="bi bi-download"></i>{" "}
        {loading ? "Génération du PDF..." : "Télécharger le PDF"}
      </>
    )}
  </PDFDownloadLink>
);

export default PreviewPDFDownloadLink;
