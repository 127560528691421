import React, { useEffect, useState } from "react";
import serverConfiguration from "../app.config.json";
import toast, { Toaster } from "react-hot-toast";
import { Link, useLocation } from "react-router-dom";
import { SaleToaster, CreateNewSale } from "./inc/CreateNewSale";
import { useAuth } from "../context";
import axios from "axios";
import TableLoader from "../layouts/TableLoader";
import Medias from "../global/Medias";
const SERVER_URL =
  serverConfiguration.env === "dev"
    ? serverConfiguration.backend.dev.SERVER
    : serverConfiguration.backend.production.SERVER;

export default function Celling() {
  const { currentUser } = useAuth();
  const location = useLocation(); // Permet d'accéder à l'URL actuelle
  const [isDefaultDivVisible, setIsDefaultDivVisible] = useState(true);
  const [urlParam, setUrlParam] = useState(null);
  const [search, setSearch] = useState("");
  const [results, setResults] = useState([]);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [dbData, setDbData] = useState([]);
  const [isLoadingDbData, setIsLoadingDbData] = useState(true);
  const [isLoadingInsertion, setIsLoadingInsertion] = useState(false);

  const parseURL = () => {
    const query = location.search; // Récupère tout ce qui est après "?"
    const param = query.replace("?", ""); // Supprime "?" pour ne garder que le paramètre
    setUrlParam(param);
    setIsDefaultDivVisible(!param); // Si un paramètre existe, cache la div par défaut
  };

  const [cart, setCart] = useState([]);
  const [clientMoney, setClientMoney] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [payementMethod, setPayementMethod] = useState("");
  const [customerName, setCustomerName] = useState("");

  // Supprime un article du panier
  const removeFromCart = (id) => {
    setCart((prevCart) => prevCart.filter((item) => item.id !== id));
    toast.success("Article retiré du panier", { position: "top-right" });
  };

  const incrementQuantity = (articleId) => {
    setResults((prevResults) =>
      prevResults.map((article) => {
        if (article.id === articleId) {
          const newQuantity = Math.min(
            (article.quantity || 1) + 1,
            article.current_stock
          );
          return { ...article, quantity: newQuantity };
        }
        return article;
      })
    );

    // Mettre à jour le panier en temps réel si l'article est déjà ajouté
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.id === articleId
          ? {
              ...item,
              quantity: Math.min((item.quantity || 1) + 1, item.current_stock),
            }
          : item
      )
    );
  };

  const decrementQuantity = (articleId) => {
    setResults((prevResults) =>
      prevResults.map((article) => {
        if (article.id === articleId) {
          return {
            ...article,
            quantity: Math.max((article.quantity || 1) - 1, 1),
          };
        }
        return article;
      })
    );

    // Mettre à jour le panier en temps réel si l'article est déjà ajouté
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.id === articleId
          ? { ...item, quantity: Math.max((item.quantity || 1) - 1, 1) }
          : item
      )
    );
  };

  // Ajoute un article au panier
  const addToCart = (article) => {
    if (+article.current_stock <= 0) {
      return;
    }
    const exists = cart.find((item) => item.id === article.id);
    if (exists) {
      if (exists.quantity + article.quantity <= article.current_stock) {
        setCart((prevCart) =>
          prevCart.map((item) =>
            item.id === article.id
              ? { ...item, quantity: item.quantity + article.quantity }
              : item
          )
        );
      }
    } else {
      // Vérifie que la quantité initiale n'excède pas le stock
      if (article.quantity <= article.current_stock) {
        setCart([...cart, { ...article }]);
      }
      if (!article.quantity) {
        article.quantity = 1; // valeur par defaut de la quantite de l'article
        setCart([...cart, { ...article }]);
      }
    }
    toast.success(`${article.name} ajouté au panier`, {
      position: "top-right",
    });
  };

  const calculateTotalPrice = () => {
    const total = cart.reduce(
      (sum, item) => sum + item.price * item.quantity,
      0
    );
    return total - discount > 0 ? total - discount : 0;
  };

  const calculateRelicat = () => {
    const total = calculateTotalPrice();
    return clientMoney - total > 0 ? clientMoney - total : 0;
  };

  const RenderResearch = () => {
    return results.length > 0 ? (
      results.map((article) => (
        <tr key={article.id}>
          <td>
            <div className="d-flex align-items-center">
              <img
                src={article.cover === "" ? Medias.category : article.cover}
                alt=""
                className="img-4by3-sm rounded-3"
                style={{ height: "55px", width: "55px" }}
              />
              <div className="ms-3">
                <h5 className="mb-0">{article.name}</h5>
                <span>{article.sub_category_name}</span>
              </div>
            </div>
          </td>
          <td className="money-font">
            {parseFloat(article.price).toLocaleString()}
          </td>
          <td>{article.current_stock}</td>
          <td>
            <div className="d-flex align-items-center">
              <div className="input-group input-spinner">
                <button
                  className="button-minus btn btn-sm"
                  onClick={() => decrementQuantity(article.id)}
                >
                  -
                </button>
                <input
                  type="number"
                  value={
                    !+article.current_stock <= 0 ? article.quantity || 1 : 0
                  } // Défaut à 1 si l'utilisateur ne change pas
                  readOnly
                  className="quantity-field form-control-sm form-input"
                />
                <button
                  className="button-plus btn btn-sm"
                  onClick={() => incrementQuantity(article.id)}
                >
                  +
                </button>
              </div>
              <div>
                <button
                  className="btn btn-ghost btn-icon btn-sm rounded-circle"
                  onClick={() => addToCart(article)}
                >
                  <i className="bi bi-cart"></i>
                </button>
              </div>
            </div>
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan={4} className="text-center">
          <img
            alt="not-found"
            src={Medias.notFound}
            style={{ height: "144px" }}
          />
        </td>
      </tr>
    );
  };

  /**
   * SALES
   */

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (cart.length === 0 || clientMoney === 0 || customerName === "") {
      toast.error("Veuillez renseigner tous les champs!", {
        position: "bottom-right",
      });
      return;
    }

    setIsLoadingInsertion(true);

    try {
      const totalAmount = calculateTotalPrice();
      const saleData = {
        customer_name: customerName.trim(),
        payment_method_id: payementMethod,
        total_amount: totalAmount,
        discount: parseFloat(discount) || 0,
        details: cart,
      };

      const message = await CreateNewSale(saleData, currentUser);

      // Mise à jour des données et réinitialisation des champs
      dataFetcher();
      resetForm();
      setIsDefaultDivVisible(true);
      toast.success(message, { position: "bottom-right" });
    } catch (error) {
      toast.error(error.message || "Une erreur est survenue.", {
        position: "bottom-right",
      });
    } finally {
      setIsLoadingInsertion(false);
    }
  };

  // Fonction utilitaire pour réinitialiser les champs du formulaire
  const resetForm = () => {
    setCart([]);
    setSearch("");
    setClientMoney(0);
    setDiscount("");
    setPayementMethod("");
    setCustomerName("");
    setResults([]);
  };

  // SEARCH
  const handleSearch = async () => {
    if (!search.trim()) {
      return;
    }
    setIsLoadingSearch(true);
    try {
      const response = await axios.get(`${SERVER_URL}/search-produce`, {
        params: { search }, // Encapsulation correcte
        headers: { Authorization: `Bearer ${currentUser}` }, // Headers séparés
      });
      setResults(response.data);
      setIsLoadingSearch(false);
      // console.log(response.data);
    } catch (error) {
      console.error("Error fetching search results:", error);
      setIsLoadingSearch(false);
    }
  };
  /**
   * DATATABLE RENDER
   */
  const DdDataRender = () => {
    return dbData.length > 0 ? (
      dbData.map((d) => (
        <tr key={d.id}>
          <td>
            {/* <Link to="{`?${d.slug}`}">{d.slug}</Link> */}
            {d.slug}
          </td>
          <td>{d.customer_name}</td>
          <td className="money-font">
            {parseFloat(d.total_amount).toLocaleString()}
          </td>
          <td className="text-success ">
            <span className="badge badge-success-soft text-success">Payé</span>
          </td>
          <td>{d.cashier_name}</td>
          <td>{d.payment_method_name}</td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan={6} className="text-center">
          <div className="py-3">
            <img
              alt="no-data"
              src={Medias.undraw_no_data}
              style={{ height: "144px" }}
            />
            <h4 className="text-muted">Aucune vente pour le moment</h4>
          </div>
        </td>
      </tr>
    );
  };
  /**
   * GET DATA
   */
  const dataFetcher = async () => {
    try {
      setIsLoadingDbData(true);
      const request = await axios.get(`${SERVER_URL}/sales`, {
        headers: { Authorization: `Bearer ${currentUser}` }, // Headers should be the third parameter
      });
      setDbData(request.data);
      // console.log(request.data);
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.message || "An error occurred", {
        position: "bottom-right",
      });
    } finally {
      setIsLoadingDbData(false);
    }
  };
  useEffect(() => {
    parseURL();
    dataFetcher();
  }, [location.search]);

  return (
    <>
      <div className="app-content-area">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              {/* Page header */}
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h3 className="mb-0">
                  {" "}
                  <i className="bi bi-cart"></i> Boutique
                </h3>
                {!isDefaultDivVisible && (
                  <Link
                    to="/celling"
                    className="btn btn-text"
                    onClick={() => setIsDefaultDivVisible(true)}
                  >
                    <i className="bi bi-arrow-left"></i> Retour
                  </Link>
                )}
              </div>
            </div>
          </div>

          {/* Liste des articles */}
          {isDefaultDivVisible && (
            <div className="card h-100">
              <div className="card-header d-md-flex justify-content-between">
                <h4 className="mb-0">Ventes</h4>

                <Link
                  to=""
                  className="btn btn-primary-soft ms-2 texttooltip"
                  data-template="trashOne"
                  onClick={() => {
                    setIsDefaultDivVisible(false);
                    resetForm();
                  }}
                >
                  <i className="bi bi-cart"></i> Nouvelle vente
                  <div id="trashOne" className="d-none">
                    <span>Nouvelle vente</span>
                  </div>
                </Link>
              </div>

              <div className="card-body">
                <div className="table-responsive table-card">
                  <table className="table text-nowrap mb-0 table-centered">
                    <thead className="table-light">
                      <tr>
                        <th>Facture</th>
                        <th>Client</th>
                        <th>
                          Valeur{" "}
                          <small className="text-secondary">(Fcfa)</small>
                        </th>
                        <th>Status</th>
                        <th>Vendeur</th>
                        <th>M.Payement</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoadingDbData ? (
                        <tr>
                          <td colSpan={6}>
                            <div className="w-100 d-flex justify-content-center align-item-center">
                              <TableLoader />
                            </div>
                          </td>
                        </tr>
                      ) : (
                        <DdDataRender />
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="card-footer border-0"></div>
            </div>
          )}

          {/* PANIER */}
          {!isDefaultDivVisible && (
            <div className="row">
              <div className="col-lg-8 col-12">
                {/* SEARCH */}
                <div className="card mb-4">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSearch();
                    }}
                  >
                    <div className="card-body">
                      <div className="d-flex align-items-end gap-3">
                        <div className="mb-3 col">
                          <label className="form-label">
                            <i className="bi bi-search"></i> Rechercher un
                            article
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Désignation, sous-catégorie, ou catégorie"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </div>
                        <div className="mb-3">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={isLoadingSearch}
                          >
                            <i className="bi bi-search"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                {/* /SEARCH */}

                <div className="card mb-4">
                  <div className="card-body px-0">
                    <div className="table-responsive rounded">
                      <table className="table text-nowrap table-centered">
                        <thead>
                          <tr>
                            <th>Désignation</th>
                            <th>Prix</th>
                            <th>Stock</th>
                            <th>Quantité</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!isLoadingSearch ? (
                            <RenderResearch />
                          ) : (
                            <tr>
                              <td colSpan={4}>
                                <div className="w-100 d-flex justify-content-center align-item-center">
                                  <TableLoader />
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              {/* Panier */}
              <div className="col-lg-4 col-12">
                <div className="card mb-4">
                  <div className="card-header py-2">
                    <h4 className="mb-0">
                      <i className="bi bi-cart"></i> Panier
                    </h4>
                  </div>
                  <div className="card-body px-0 pt-0">
                    <table className="table text-nowrap table-centered">
                      <thead>
                        <tr>
                          <th>Désignation</th>
                          <th>Qte</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cart.map((item) => (
                          <tr key={item.id}>
                            <td>
                              <div className="d-flex align-items-center">
                                <img
                                  src={
                                    item.cover === ""
                                      ? Medias.category
                                      : item.cover
                                  }
                                  alt=""
                                  className="rounded-3"
                                  style={{ height: "34px", width: "34px" }}
                                />
                                <div className="ms-3">
                                  <h5 className="mb-0">{item.name}</h5>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex gap-2 align-items-center">
                                <span>{item.quantity}</span>
                                <button
                                  className="btn btn-ghost btn-icon btn-sm rounded-circle texttooltip"
                                  title="supprimer du panier"
                                  onClick={() => removeFromCart(item.id)}
                                >
                                  <i className="bi bi-trash"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* Valeur */}
                <div className="card mb-4">
                  <div className="p-3 d-flex justify-content-between">
                    <h4 className="text-uppercase">Total</h4>
                    <h4 className="text-dark money-font">
                      {calculateTotalPrice().toLocaleString()} FCFA
                    </h4>
                  </div>
                </div>
                {/* Valeur */}
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="form-group mb-3">
                      <label htmlFor="discount">Remise</label>
                      <input
                        type="number"
                        className="form-control"
                        id="discount"
                        name="discount"
                        min={0}
                        value={discount}
                        onChange={(e) => setDiscount(Number(e.target.value))}
                      />
                    </div>
                  </div>
                </div>
                {/* Client */}
                <div className="card mb-4">
                  <div className="card-header py-2">
                    <h4 className="mb-0">
                      <i className="bi bi-person"></i> Client
                    </h4>
                  </div>
                  <div className="card-body">
                    <div className="form-group mb-3">
                      <label htmlFor="clientName">Nom du client</label>
                      <input
                        type="text"
                        className="form-control"
                        id="clientName"
                        name="clientName"
                        value={customerName}
                        onChange={(e) => setCustomerName(e.target.value)}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="clientMoney">Montant du client</label>
                      <input
                        type="number"
                        className="form-control"
                        id="clientMoney"
                        name="clientMoney"
                        min={0}
                        value={clientMoney}
                        onChange={(e) => setClientMoney(Number(e.target.value))}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          value="espece"
                          id="espece"
                          name="money"
                          onChange={(e) => setPayementMethod(e.target.value)}
                        />
                        <label className="form-check-label" htmlFor="espece">
                          Espèce
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          value="depot"
                          id="depot"
                          name="money"
                          onChange={(e) => setPayementMethod(e.target.value)}
                        />
                        <label className="form-check-label" htmlFor="depot">
                          Dépot
                        </label>
                      </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between">
                      <h4 className="text-uppercase">Relicat</h4>
                      <h4 className="text-dark money-font">
                        {calculateRelicat().toLocaleString()} FCFA
                      </h4>
                    </div>
                  </div>
                </div>
                {/* client */}

                <div className="d-grid">
                  {isLoadingInsertion && isLoadingDbData ? (
                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled
                      style={{ cursor: "not-allowed" }}
                    >
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleSubmit}
                    >
                      Valider la vente
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <SaleToaster />
        <Toaster />
      </div>
    </>
  );
}
