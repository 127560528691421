import React from "react";
import Medias from "../global/Medias";

export default function Loader() {
  return (
    <div className="loaderApp">
      {/* Arrière-plan flouté */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: `url(${Medias.patern_background})`,
          backgroundSize: "cover",
          filter: "blur(8px)",
          zIndex: 1,
        }}
      />
      <div
        style={{ position: "relative", zIndex: 2 }}
      >
        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
}
