import React, { useState } from "react";
import { Link } from "react-router-dom";
import { formatDateToYYYYMMDDH } from "../../global/DateFormater";

export default function OtherDateModal({
  dateRange,
  setDateRange,
  handleDateRange,
}) {
  const [isModalVisible, setModalVisible] = useState(false);
  const handleStartDateTimeChange = (e) => {
    setDateRange({ ...dateRange, startDate: e.target.value });
  };

  const handleEndDateTimeChange = (e) => {
    setDateRange({ ...dateRange, endDate: e.target.value });
  };

  const applyDateRange = () => {
    setDateRange({
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    }); // Mise à jour dans le parent
    handleDateRange(); // Appel pour déclencher l'action
  };

  return (
    <>
      <Link
        to=""
        className="btn btn-primary-soft ms-2 texttooltip"
        data-template="trashOne"
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
        onClick={() => setModalVisible(true)}
      >
        <i className="bi bi-clipboard-data"></i> Autre rapport
        <div id="trashOne" className="d-none">
          <span>Autre rapport de vente</span>
        </div>
      </Link>
      <div
        className={`modal fade ${isModalVisible ? "show" : ""}`}
        style={{ display: isModalVisible ? "block" : "none" }}
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Veuillez indiquer la date de début et de fin
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setModalVisible(false)}
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="form-group mb-3 col-md-6">
                  <label htmlFor="startDateTime">Date de début et heure:</label>
                  <input
                    type="datetime-local"
                    id="startDateTime"
                    value={dateRange.startDate}
                    onChange={handleStartDateTimeChange}
                    className="form-control"
                  />
                </div>

                <div className="form-group mb-3 col-md-6">
                  <label htmlFor="endDateTime">Date de fin et heure</label>
                  <input
                    type="datetime-local"
                    id="endDateTime"
                    value={dateRange.endDate}
                    onChange={handleEndDateTimeChange}
                    className="form-control"
                  />
                </div>
                <div className="col-md-12">
                  <h4>Date et heure sélectionnées</h4>
                  <h4>
                    <span className="badge text-bg-secondary">
                      <strong>Début :</strong>{" "}
                      {formatDateToYYYYMMDDH(dateRange.startDate) ||
                        "Non sélectionné"}
                    </span>
                  </h4>

                  <h4>
                    <span className="badge text-bg-primary">
                      <strong>Fin :</strong>{" "}
                      {formatDateToYYYYMMDDH(dateRange.endDate) ||
                        "Non sélectionné"}
                    </span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                data-bs-dismiss="modal"
                onClick={() => setModalVisible(false)}
              >
                Annuler
              </button>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={applyDateRange}
              >
                Obtenir
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
