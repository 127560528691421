import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import serverConfiguration from "../app.config.json";
import { useAuth } from "../context";
import LoaderDb from "../layouts/LoaderDb";
const SERVER_URL =
  serverConfiguration.env === "dev"
    ? serverConfiguration.backend.dev.SERVER
    : serverConfiguration.backend.production.SERVER;

export default function Roles() {
  // USER
  const { currentUser } = useAuth();
  // DATA OF INPUT
  const [values, setValues] = useState({
    name: "",
    role_id: "",
  });
  const [isUpdateData, setIsUpdateData] = useState(false);
  const [isLoadingDbData, setIsLoadingDbData] = useState(true);
  const [dbData, setDbData] = useState([]);

  const handleSubmitForm = (e) => {
    e.preventDefault();
    if (values.name === "") {
      toast.error("Veuillez renseigner le champs!", {
        position: "bottom-right",
      });
      return;
    }
    toast.promise(
      new Promise(async (resolve, reject) => {
        try {
          // Set URL based on whether it's an update or new data
          const url = isUpdateData
            ? `${SERVER_URL}/update-role`
            : `${SERVER_URL}/new-role`;

          const request = await axios.post(
            url, // Use the appropriate URL
            values, // Request body should be the second parameter
            {
              headers: { Authorization: `Bearer ${currentUser}` }, // Headers should be the third parameter
            }
          );

          // console.log(request);
          resolve(request.data.message);
          dataFetcher();

          // Clean form DOM
          setIsUpdateData(false);
          setValues({
            name: "",
            role_id: "",
          });
        } catch (error) {
          console.log(error);
          reject(error.response?.data?.message || "An error occurred");
        }
      }),

      {
        loading: "Enregistrement en cours!",
        success: (data) => data,
        error: (err) => err,
      },
      {
        position: "bottom-right",
        success: {
          duration: 5000,
          icon: "🔥",
        },
      }
    );
  };

  const handleRemoveTheRole = async (id) => {
    try {
      setIsLoadingDbData(true);
      await axios.post(
        `${SERVER_URL}/remove-role`, // Use the appropriate URL
        { role_id: id }, // Request body should be the second parameter
        {
          headers: { Authorization: `Bearer ${currentUser}` }, // Headers should be the third parameter
        }
      );
    } catch (error) {
      console.log(error);
      toast.error("Erreur de chargement des données", {
        position: "bottom-right",
      });
    } finally {
      setIsLoadingDbData(false);
      dataFetcher();
    }
  };

  /**FETCH DATA */
  const dataFetcher = async () => {
    try {
      const request = await axios.get(`${SERVER_URL}/get-roles`, {
        headers: { Authorization: `Bearer ${currentUser}` }, // Headers should be the third parameter
      });
      setDbData(request.data);
      // console.log(request.data);
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.message || "An error occurred", {
        position: "bottom-right",
      });
    } finally {
      setIsLoadingDbData(false);
    }
  };
  useEffect(() => {
    dataFetcher();
  }, []);

  return (
    <div className="app-content-area">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            {/* <!-- Page header --> */}
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h3 className="mb-0">
                <i className="icofont-duotone icofont-shield"></i> Gerer les
                roles du système
              </h3>
            </div>
          </div>

          {/* app */}
          <div className="col-12 animate-right">
            <div className="card">
              <div className="card-header d-md-flex border-bottom">
                <div className="col-md-6">
                  <h5>Liste & option de gestion des roles</h5>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="table-responsive col-6">
                    <table className="table table-sm">
                      <thead>
                        <tr>
                          <th scope="col">Rôle</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoadingDbData ? (
                          dbData.length === 0 ? (
                            <tr>
                              <td colSpan={2} className="text-center">
                                -- Aucune informations --
                              </td>
                            </tr>
                          ) : (
                            dbData.map((d) => (
                              <tr key={d.role_id}>
                                <td>{d.name}</td>
                                <td>
                                  <div className="">
                                    <button
                                      title="Editer les information de cet emplacement"
                                      className="btn btn-text btn-sm"
                                      onClick={() => {
                                        setIsUpdateData(true);
                                        setValues({
                                          role_id: d.role_id,
                                          name: d.name,
                                        });
                                      }}
                                    >
                                      <i className="icofont-edit"></i>
                                    </button>
                                    <button
                                      title="Supprimer cet emplacement"
                                      className="btn btn-text btn-sm"
                                      onClick={() => {
                                        handleRemoveTheRole(d.role_id);
                                      }}
                                    >
                                      <i className="icofont-delete"></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))
                          )
                        ) : (
                          <tr>
                            <td colSpan={2} className="text-center">
                              <div>
                                <LoaderDb />
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-6 position-sticky">
                    {isUpdateData && (
                      <div className="row justify-content-end">
                        <div className="col-6 text-end">
                          <button
                            onClick={() => {
                              setIsUpdateData(false);
                              setValues({
                                name: "",
                                role_id: "",
                              });
                            }}
                            className="btn btn-text text-danger btn-sm text-end px-0"
                          >
                            <i className="icofont-close"></i> Anuler les
                            modification
                          </button>
                        </div>
                      </div>
                    )}
                    {/* FORM */}
                    <form
                      onSubmit={handleSubmitForm}
                      className="row sticky-top"
                    >
                      <div className="col-7">
                        <label
                          htmlFor="validationCustom01"
                          className="form-label"
                        >
                          Rôle
                        </label>
                        <input
                          type="text"
                          className="form-control col"
                          id="validationCustom01"
                          placeholder="Ex: Standard"
                          required
                          value={values.name}
                          onChange={(e) =>
                            setValues({ ...values, name: e.target.value })
                          }
                        />
                      </div>
                      <div className="col d-flex align-items-end justify-content-end">
                        <button
                          id="submitBtn"
                          className="btn btn-success col"
                          type="submit"
                        >
                          <i className="icofont-save icofont-duotone"></i>{" "}
                          Enregistrer
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* app */}
        </div>
      </div>
      <Toaster />
    </div>
  );
}
