import React, { useCallback, useState } from "react";
import Medias from "../../global/Medias";
import { Link } from "react-router-dom";
import axios from "axios";
import SearchBarProduce from "./SearchBarProduce";
import DetailsViewProduce from "./DetailsViewProduce";
import toast from "react-hot-toast";

export default function ProducesManager({
  handleCancelForm,
  currentUser,
  server,
}) {
  const [showDetailsOfResult, setShowDetailsOfResult] = useState(false);
  const [dataForDetail, setDataForDetail] = useState({});

  const handleShowDetails = (d) => {
    setShowDetailsOfResult(true);
    setDataForDetail(d);
  };
  const handleHideDetails = () => setShowDetailsOfResult(false);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [search, setSearch] = useState("");
  const [results, setResults] = useState([]);
  const [aprov, setAprov] = useState(0);

  const handleInputChange = useCallback((e) => {
    setAprov(e.target.value);
  }, []);

  const handleSearch = async (e) => {
    e.preventDefault();
    if (!search.trim()) {
      return;
    }
    setIsLoadingSearch(true);
    try {
      const response = await axios.get(`${server}/search-produce`, {
        params: { search }, // Encapsulation correcte
        headers: { Authorization: `Bearer ${currentUser}` }, // Headers séparés
      });
      setResults(response.data);
      setIsLoadingSearch(false);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching search results:", error);
      setIsLoadingSearch(false);
    }
  };

  /**
   * APROV
   * @returns void
   */
  const createProductMovement = () => {
    if (+aprov <= 0 || typeof +aprov !== "number") {
      toast.error("Veuillez renseigner une valeur valide!", {
        position: "bottom-right",
      });
      return;
    }
    toast.promise(
      new Promise(async (resolve, reject) => {
        try {
          const url = `${server}/new-aprov`;

          const request = await axios.post(
            url,
            {
              aprov_value: +aprov,
              produce_id: dataForDetail.id,
            },
            {
              headers: {
                Authorization: `Bearer ${currentUser}`,
              },
            }
          );

          resolve(request.data.message);
          handleHideDetails();
          setAprov(0);
          setResults([]);
          setSearch("");
        } catch (error) {
          reject(error.response?.data?.message || "Une erreur est survenue");
        }
      }),
      {
        loading: "Enregistrement en cours!",
        success: (data) => data,
        error: (err) => err,
      },
      {
        position: "bottom-right",
        success: {
          duration: 5000,
          icon: "🔥",
        },
      }
    );
  };

  const TableView = () => (
    <div className="table-responsive">
      <table className="table table-sm mt-5 table-hover">
        <thead>
          <tr>
            <th>Désignation</th>
            <th>Sous-catégorie</th>
            <th>Stock</th>
          </tr>
        </thead>
        <tbody>
          {results.length > 0 ? (
            results.map((d) => (
              <tr key={d.id}>
                <td>
                  <Link
                    onClick={() => handleShowDetails(d)}
                    to="#"
                    className="btn-link"
                  >
                    {d.name}
                  </Link>
                </td>
                <td>{d.sub_category_name}</td>
                <td>
                  <span
                    className={`${
                      +d.current_stock === 0 ? "text-danger h5" : ""
                    }`}
                  >
                    {d.current_stock}
                  </span>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={3} className="text-center py-3">
                <img
                  alt="no-data"
                  src={Medias.notFound}
                  style={{ height: "89px" }}
                />
                <h4 className="text-muted">Aucune resultat</h4>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );

  return (
    <div className="col-12 animate-left">
      <div className="card">
        <div className="card-header d-md-flex border-bottom border-bottom-dark">
          <div className="col-md-6">Parametres des stock</div>
          <div className="col-md-6 d-flex justify-content-end">
            <button
              type="button"
              onClick={handleCancelForm}
              className="btn btn-text btn-sm text-danger"
              title="Annuler le formulaire et retourner à la liste"
            >
              <i className="icofont-close-circled"></i> Annuler
            </button>
          </div>
        </div>
        <div className="card-body">
          {!showDetailsOfResult && (
            <SearchBarProduce
              handleSearch={handleSearch}
              setSearch={setSearch}
              search={search}
              isLoadingSearch={isLoadingSearch}
            />
          )}
          {!showDetailsOfResult ? (
            <TableView />
          ) : (
            <DetailsViewProduce
              dataForDetail={dataForDetail}
              aprov={aprov}
              handleInputChange={handleInputChange}
              handleHideDetails={handleHideDetails}
              Medias={Medias}
              createProductMovement={createProductMovement}
            />
          )}
        </div>
      </div>
    </div>
  );
}
