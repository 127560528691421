import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "./layouts/Loader";
import Header from "./layouts/Header";
import { useAuth } from "./context";
import SidBar from "./layouts/Sidbar";
import Medias from "./global/Medias";

export default function PrivateRoute({ children }) {
  const navigate = useNavigate();
  const [isToggle, setIsToggle] = useState(false);
  const { currentUser } = useAuth();

  useEffect(() => {
    if (!currentUser) {
      navigate("/login", { replace: true });
    }
  }, [currentUser, navigate]);

  // Afficher le Loader tant que l'utilisateur n'est pas vérifié
  if (!currentUser) {
    return <Loader />;
  }

  return (
    <main
      id="main-wrapper"
      className={`main-wrapper ${isToggle ? "toggled" : ""}`}
    >
      {/* Header */}
      <Header isToggle={isToggle} setIsToggle={setIsToggle} />

      {/* Sidebar */}
      <SidBar />

      {/* Application Content */}
      <div id="app-content" style={{ background: `url(${Medias.patern_background})` }}>{children}</div>
    </main>
  );
}
