import React from "react";

export default function ChartBtn({setIsShowChart, isShowChart}) {
  return (
    <div className="show-chart">
      <button type="button" className="btn btn-primary rounded-circle" onClick={() => setIsShowChart(!isShowChart)}>
        <i className="bi bi-graph-up"></i>
      </button>
    </div>
  );
}
