import React, { useEffect, useState } from "react";
import config from "../app.config.json";
import axios from "axios";
import Media from "../global/Medias";
import { useAuth } from "../context";
import { Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import Medias from "../global/Medias";

export default function Home() {
  const { userCredentials, currentUser } = useAuth();

  const [isLoadingDbData, setIsLoadingDbData] = useState(true);
  const [dbData, setDbData] = useState({});

  const getDevicePercentages = () => {
    const { total, deviceCount } = dbData.visitorsDevice;

    // Vérification pour éviter la division par zéro
    if (total === 0) {
      return {
        phone: 0,
        tablet: 0,
        computer: 0,
      };
    }

    return {
      phone: (deviceCount.phone / total) * 100,
      tablet: (deviceCount.tablet / total) * 100,
      computer: (deviceCount.computer / total) * 100,
    };
  };

  /**FETCH DATA */
  const dataFetcher = async () => {
    try {
      const request = await axios.get(
        `${config.backend.dev.SERVER}/dashboard`,
        {
          headers: { Authorization: `Bearer ${currentUser}` }, // Headers should be the third parameter
        }
      );
      setDbData(request.data);
      // console.log(request.data);
      setIsLoadingDbData(false);
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.message || "An error occurred", {
        position: "bottom-right",
      });
    }
  };

  // useEffect(() => {
  //   dataFetcher();
  // }, []);
  return (
    <>
      <div className="app-content-area animate-bottom">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              {/* <!-- Page header --> */}
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h3 className="mb-0">
                  <i className="bi bi-house"></i> Tableau de bord
                </h3>
              </div>

              <div className="row d-none">
                <div className="col-xl-9 col-12 mb-5">
                  <div className="card h-100">
                    <div className="card-header d-flex justify-content-between align-items-center">
                      <h4 className="mb-0">Revenue</h4>
                    </div>
                    <div className="card-body">
                      <div className="mt-4 px-lg-6 ">
                        <div className="row bg-light rounded-3 ">
                          <div className="col-lg-4 col-md-6">
                            <div className="p-4">
                              <span>
                                <i className="mdi mdi-circle small me-1 text-primary"></i>
                                Current Week
                              </span>
                              <h3 className="mb-0  mt-2">$235,965</h3>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6">
                            <div className="p-4">
                              <span>
                                <i className="mdi mdi-circle small me-1 text-info"></i>
                                Past Week
                              </span>
                              <h3 className="mb-0  mt-2">$198,214</h3>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6">
                            <div className="p-4">
                              <span>Today's Earning: </span>
                              <h3 className="mb-0  mt-2">$2,562.30</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-12">
                  <div className="col mb-5">
                    <div className="card h-100 card-lift">
                      <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                          <span className="text-muted fw-semi-bold ">
                            Ventes journalière
                          </span>
                          <span style={{ fontSize: "1.5rem" }}>
                            <i className="bi bi-cart text-primary"></i>
                          </span>
                        </div>
                        <div className="mt-4 mb-3 d-flex align-items-center lh-1">
                          <h3 className="fw-bold  mb-0">50</h3>
                          <span className="mt-1 ms-2 text-danger ">
                            <i className="bi bi-arrow-down"></i>12%
                          </span>
                        </div>
                        <Link to="/celling" className="btn-link fw-semi-bold">
                          Allez au ventes
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col mb-5">
                    <div className="card h-100 card-lift">
                      <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                          <span className="text-muted fw-semi-bold ">
                            Revenue
                          </span>
                          <span style={{ fontSize: "1.5rem" }}>
                            <i className="bi bi-piggy-bank text-primary"></i>
                          </span>
                        </div>
                        <div className="mt-4 mb-3 d-flex align-items-center lh-1">
                          <h3 className="fw-bold  mb-0">10 000</h3>
                          <span className="mt-1 ms-2 text-danger ">
                            <i className="bi bi-arrow-down"></i>15%
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className="card d-flex justify-content-center align-items-center p-5"
                  style={{ height: "calc(100vh - 233px)" }}
                >
                  <img
                    src={Medias.workInProgress}
                    alt="coming soon"
                    style={{
                      width: "144px",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Toaster />
      </div>
    </>
  );
}
